import { React, useEffect, useState } from "react";
import EventList from "../elements/EventList";
import MainBanner from "../elements/MainBanner";
import Beer from "../elements/Beer";
import resources from "../../resources.json";

function Home(props) {
  useEffect(() => {
    document.title = "Polepšovna ducha";
  }, []);

  // alert("Omlouváme se, ale v SOBOTU 24.8. bude Polepšovna z důvodu konání soukromé akce uzavřena");
  // alert("Dnes je budova Polepšovny uzavřena pro soukromou událost. Rádi vás obsloužíme u foodtrucku, kde teče i naše pivo.");

  return (
    <>
      <MainBanner
        imgSrc="assets/images/banners/pd_main_2.jpeg"
        subheader="Oáza přírody a skvělého řemeslného piva kousek od Prahy"
        mainHeader="POLEPŠOVNA DUCHA"
      />
      <section
        id="wedding"
        className="heading heading-1 bg-white menu menu-7 menu-banner text-center"
      >
        <div className="container pb-90">
          <div className="row clearfix">
            <div className="col-xs-12 col-sm-12 col-md-8 col-md-offset-2">
              <div className="text--center">
                <p className="heading--desc">
                  Hledáte místo k odpočinku a relaxaci po náročném týdnu? Tak to
                  byste měli navštívit Polepšovnu ducha, která se nachází jen
                  pár kilometrů za Prahou. V této oáze klidu a přírody se budete
                  cítit jako doma. Na zahradě rostou ovocné stromy a uvnitř
                  najdete ty nejširší úsměvy a nejrychleji načepované půllitry.
                  Od roku 2022 jsme se začali věnovat vaření vlastních pivních
                  specialit a postupně se snažíme postavit pivovar přímo u nás v
                  Řeži. A co se týče zábavy, pořádáme pravidelně koncerty,
                  divadla, kvízy a další kulturní a sportovní akce. Tak
                  neváhejte a přijďte si užít pohlazení na duchu. 🍻
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="container">
          <div className="row clearfix">
            <div className="col-xs-12 col-sm-12 col-md-8 col-md-offset-2">
              <div className="heading heading-1 mb-50 text--center">
                <h2 className="heading--title mb-0">Aktuálně na čepu</h2>
                <div className="divider--shape-4"></div>
              </div>
            </div>
          </div>
          <div class="row">
            {resources.beers.map(
              (item, id) =>
                item.onTap && (
                  <Beer
                    key={id}
                    id={id}
                    srcSmall={process.env.PUBLIC_URL + item.srcSmall}
                    srcFull={process.env.PUBLIC_URL + item.srcFull}
                    name={item.name}
                    descriptionShort={item.descriptionShort}
                    description={item.description}
                    header={item.header}
                    abv={item.abv}
                    ibu={item.ibu}
                    ebc={item.ebc}
                  />
                )
            )}
          </div>
        </div>

        <EventList setEvent={props.setEvent} />
        <div className="row clearfix">
          <div className="col-xs-12 col-sm-12 col-md-8 col-md-offset-2">
            <div className="heading heading-1 mb-50 text--center">
              <h2 className="heading--title mb-0">Nabídka</h2>
              <div className="divider--shape-4"></div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div>
              <img
                class="col-xs-12 col-sm-6"
                src="assets/images-pd/home/menu_pd1.jpeg"
                alt="menu1"
              ></img>
            </div>
            <div>
              <img
                class="col-xs-12 col-sm-6"
                src="assets/images-pd/home/menu_pd2.jpeg"
                alt="menu2"
              ></img>
            </div>
          </div>
        </div>
      </section>

      <div class="module module-reservation">
        <a
          class="btn-popup"
          data-toggle="modal"
          data-target="#reservationModule"
        >
          <i class="lnr lnr-users"></i>Reservation
        </a>
        <div
          class="modal fade reservation-popup"
          tabindex="-1"
          role="dialog"
          id="reservationModule"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i class="fa fa-times"></i>
                </button>
                <div class="row reservation">
                  <div class="col-xs-12 col-sm-12 col-md-12">
                    <div class="reservation-form mb-30 bg-white text-center">
                      <form
                        method="post"
                        action="assets/php/reservation.php"
                        class="reservationForm mb-0"
                      >
                        <div class="row">
                          <div class="col-xs-12 col-sm-12 col-md-4">
                            <div class="form-select">
                              <i class="fa fa-angle-down"></i>
                              <select
                                class="form-control"
                                name="res-pepole"
                                id="resPepole"
                              >
                                <option value="2">2 People</option>
                                <option value="3">3 People</option>
                                <option value="4">4 People</option>
                                <option value="5">5 People</option>
                                <option value="6">6 People</option>
                                <option value="8">8 People</option>
                                <option value="10">10 People</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-xs-12 col-sm-12 col-md-4">
                            <div class="form-select">
                              <i class="fa fa-angle-down"></i>
                              <select
                                class="form-control"
                                name="res-day"
                                id="resDay"
                              >
                                <option value="March 23">March 23, 2017</option>
                                <option value="March 24">March 24, 2017</option>
                                <option value="March 25">March 25, 2017</option>
                                <option value="March 26">March 26, 2017</option>
                                <option value="March 27">March 27, 2017</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-xs-12 col-sm-12 col-md-4">
                            <div class="form-select">
                              <i class="fa fa-angle-down"></i>
                              <select
                                class="form-control"
                                name="res-time"
                                id="resTime"
                              >
                                <option value="8:00">8:00 PM</option>
                                <option value="9:00">9:00 PM</option>
                                <option value="10:00">10:00 PM</option>
                                <option value="11:00">11:00 PM</option>
                                <option value="12:00">12:00 AM</option>
                              </select>
                            </div>
                          </div>

                          <div class="col-xs-12 col-sm-12 col-md-12">
                            <div class="reservation-result"></div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12">
                    <div class="text-uppercase text-center">
                      <strong>
                        <span class="text-white">Reservation Powered by</span>{" "}
                        <a class="reservation-link" href="#">
                          Opentable
                        </a>
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
